import firebase from "firebase/compat/app";
import "firebase/compat/database";
import 'firebase/compat/auth';
// import { config } from 'dotenv';
// config();
// require('dotenv').config()

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };

// using the const or let command
const fireDb = firebase.initializeApp(firebaseConfig);
const fireAuth = firebase.initializeApp(firebaseConfig);
//firebase.initializeApp(firebaseConfig);
//firebase.initializeApp(firebaseConfig);
//console.log(fireDb);
//firebase.initializeApp(firebaseConfig);

//export default firebase
export default fireDb;