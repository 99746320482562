import React from 'react'

function PrivacyPolicy() {
  return (
    <div>
        <section>
            <h1>Privacy Policy</h1>
            <p>Muvr Proprietary Limited respects your privacy and protecting your information is
            paramount. Please read this Privacy and Cookie Policy carefully before using our
            website or registering to use our services in order to understand our views and practices
            regarding your personal data and how we will treat it. This Privacy Policy sets out the
            basis on which any personal data will be processed by us.
            By visiting our website you are accepting and consenting to the practices described in
            this Privacy Policy. For the purpose of this Privacy Policy “applicable data protection
            law” means any laws in your jurisdiction in relation to data protection and privacy.
            The data controller is Muvr Proprietary Limited of 4095 Lily Court Westbury
            Johannesburg 2093
            References in this Privacy Policy and on our website to “we” , “our” or “us” are
            references to Muvr Proprietary Limited. References to “you” and “your” means each
            natural or legal person who uses our website or the associated services.
            For the avoidance of doubt, we will at all times collect, maintain, use and retain your
            information in accordance with the National Credit Act, 2005 (“NCA”).
            How we collect data about you and your use of our website
            We may collect and process the following data about you:
            Information you give us. You may give us information about you when you register for
            our service or by communicating with us by phone, email or otherwise. This includes
            information you provide when you subscribe to our services, participate in discussion
            boards or other social media functions on our website, provide us with feedback,
            participate in surveys, and when you report a problem with our website. The information
            you give us may include your name, address, email address, phone number or date of
            birth, financial information relating to your payments you make or receive or bank
            account information.
            Information we collect about you. Each time you visit our website we may automatically
            collect the following information: (a) technical information, including the Internet protocol
            (IP) address used to connect your device to the Internet, your login information, browser
            type and version, time zone setting, browser plug-in types and versions, operating
            system and platform; (b) information about your visit, including the full Uniform
            Resource Locators (URL) clickstream to, through and from our website (including date
            and time); products you viewed or searched for; page response times, download errors,
            length of visits to certain pages, page interaction information (such as scrolling, clicks,
            and mouse-overs), and methods used to browse away from the page and any phone
            number used to call our client service number.
            Information we receive from other sources. We may receive information about you if you
            use any of the other websites we operate or the other services we provide. We are also
            working closely with third parties (including, for example, business partners, service
            providers, advertising networks, analytics providers, search information providers.</p>
            <h2>Uses made of the information</h2>
            <p>
            We use information held about you in the following ways:
              Information you give to us or other third parties on your behalf as part of our services.
              We will use this information to:
              (a) administer your account and relationship with us and to, communicate with you by
              telephone, mail, email, text (SMS) message, instant messaging or other electronic
              means;
              (b) verify your identity as part of our identity authentication process (we will use the
              credit card or bank account information only for the purposes of verifying your identity
              (and to prevent, detect and prosecute fraud and crime and comply with legal or
              regulatory requirements) and will not retain it.
              (c) provide you with the information, products and services that you request from us;
              (d) provide you with information about other products and services we offer that are
              similar to those that you have already purchased;
              (e) provide you with information about products or services we feel may interest you or
              be best for you, where you have consented to receive such information;
              (g) your data may be shared with product providers to validate if you are an existing
              customer (which may affect whether you can be accepted for one of their products) or
              for fraud prevention purposes. The product provider does not have permission to use
              these data for any other purpose including marketing.
              (h) notify you about changes to our services;
              (i) ensure that content from our website is presented in the most effective manner for
              you and your device;
              (j) aggregate it on an anonymous basis with other data for data analytics and reporting
              purposes.
              Information we collect about you. We will use this information:
              (a) to administer our website and for internal operations, including troubleshooting, data
              analysis, testing, research, statistical and survey purposes;
              (b) to build up a picture of your interests so that you don’t miss information relevant to
              you when you visit our website;
              (c) to improve the services we offer you and to try and ensure that you get the best from
              our website and application, ensuring that content is presented in the most effective
              manner for you and for the devices you use to access it.
              (d) to allow you to participate in interactive features of our service, when you choose to
              do so;
              (e) as part of our efforts to keep our website and application safe and secure.
              (f) to measure or understand the effectiveness of advertising we serve to you and
              others, and to deliver relevant advertising to you;
              (g) to make suggestions and recommendations to you and other users of our website
              about products or services that may interest you or them, subject to having received
              your consent where necessary.
              Information we receive from other sources. We may combine this information with
              information you give to us and information we collect about you. We may use this
              information and the combined information for the purposes set out above.
              We may monitor or record telephone conversations or other communications between
              you and us. We will use these recordings or transcripts of them to check your
              instructions to us, analyze, assess and improve our services to customers, for training
              and quality purposes and for the purposes of investigating any complaint you may
              make, or as evidence in any dispute or anticipated disputes between you and us.
              Who your data can be disclosed to, disclosure of your data to others may be necessary
              to ensure the smooth provision to you of products, services and information you
              request. Your data may be disclosed to the other entities as described below.
              </p>

            <p>
              We may share your personal information with any of the following:
              (a)Fraud prevention agencies, to prevent crime and trace those responsible (please see
              “Fraud Prevention Agencies” below for more detail).
              (b)Business partners, suppliers, and sub-contractors for the performance of any
              contract we enter with you.
              (c)Advertisers and advertising networks that require the data (in an aggregated format
              whereby individuals cannot be individually identified), to select and serve relevant
              adverts to you and others and to make sure their adverts reach their intended audience
              (for example, by telling them how many women or men have clicked on an advert);
              (d)Analytics and search engine providers that assist us in the improvement and
              optimisation of our website.
              We may disclose your personal information to third parties if:
              (a) we sell or buy any business or assets, in which case we may disclose your personal
              data to the prospective seller or buyer of such business or assets;
              (b) Muvr Proprietary Limited or substantially all its assets are acquired by a third party,
              in which case personal data held by it about its customers will be one of the transferred
              assets; and
              (c) we are under a duty to disclose or share your personal data in order to comply with
              any legal obligation, or in order to enforce or apply our terms and other agreements; or
              to protect the rights, property, or safety of Muvr Proprietary Limited, our customers,
              and/or others. This includes exchanging information with other companies and
              organizations for the purposes of fraud protection.
              If false or inaccurate information is provided and fraud is identified, details of this fraud
              will be passed to the relevant agencies. Law enforcement agencies may access and
              use this information. Other organizations may also access and use this information to
              prevent fraud and money laundering, for example when:
              (a) checking details on applications for credit and credit related or other facilities;
              (b) managing credit and credit related accounts or facilities;
              (c) recovering debt;
              (d) checking details on proposals and claims for all types of insurance; and (e) checking
              details of job applicants and employees.
              We and other organizations may access and use from other countries the information
              recorded by fraud prevention agencies.
              We offer you the opportunity to receive information from us. We will normally send direct
              marketing by email if we have your email address, but may choose to contact you via
              other methods such as telephone or SMS or mail.
              If you would like us to stop sending direct marketing to you, we offer simple ways to do
              this. Whenever you receive direct marketing you will be told how to unsubscribe.
              If you consented to receive marketing communications when you registered for our
              website or services, you can tell us that you do not wish to receive any more by logging
              into your Muvr account and going into the “My Account” section. At the bottom of the
              page, you can click on “Change” under “Change what emails you receive from us” to
              easily switch off all marketing emails.
            </p>
            <h2>Where your data is stored</h2>
            <p>
            All information you provide us with is stored on our secure servers.
            The transmission of information via the internet may not always be completely secure.
            Although we will do our best to protect your personal data, we cannot guarantee the
            security of your data transmitted to our website; any transmission is at your own risk.
            Once we have received your information, we will use strict procedures and security
            features to try to prevent unauthorized access, loss or damage.
            </p>

            <h2>How long your information is kept</h2>
            <p>
            How long we keep your information will depend on the purpose for which we use it. We
            will only retain your information for as long as is necessary for those purposes.
            Please note that if you withdraw consent to the processing of your personal data,
            access to our website and the associated services will be suspended or terminated. We
            will keep an archived record of your personal data for a period of up to 7 years after
            termination (unless a longer period is prescribed by law) for the purposes of responding
            to legal disputes and legal or regulatory enquiries or investigations only, but will not use
            this data for any other purpose.
            Third party websites
            Our website may, from time to time, contain links to and from the websites of our partner
            networks, advertisers and affiliates. If you follow a link to any of these websites, please
            note that these websites have their own privacy policies and that we do not accept any
            responsibility or liability for these policies. Please check these policies before you
            submit any personal data to these websites.
            </p>

            <h2>Requesting a copy of your personal information</h2>
            <p>
            You may at any time request us to confirm whether we hold information about you. We
            will respond to such a request at no charge. You may request a copy of any personal
            data about you held by us, and details of any third parties who have or have had access
            to your information. We will respond within a reasonable time.
            Any access request may be subject to a fee prescribed by the applicable data protection
            law in order to meet our costs in providing you with details of the information we hold
            about you. If a fee is payable, you may request an estimate of the fee before requesting
            a copy of your personal information. We may require you to pay a deposit for all or part
            of the fee.
            The request must be in writing and must contain the following:
            (a)your name and postal address
            (b) a telephone number where you can be reached.
            (c) a photocopy of your passport or driving license;
            (d) your signature and the date of the request;
            (e) signed authority from the individual whose data is required if you are applying on
            their behalf.
            Please send your request to:
            Changes to our privacy policy
            Changes we make to this Policy
            Any changes we may make to our Privacy Policy in the future will be posted on this
            page and, where appropriate, notified to you by email. Please check back frequently to
            see any updates or changes to our Privacy Policy.
            Contact
            Questions, comments, and requests regarding this Privacy Policy should be addressed
            to:
            If you require any further guidance on updating the details, we hold about you, please
            contact us: muverza@gmail.com
            </p>

            <h2>Muvr Cookies Policy</h2>
            <p>
            Muvr uses cookies for a variety of purposes, including ensuring that our website
            functions as expected, remembering your preferences and gathering data on the users
            of our website and our services.
            By using our website (through any device) you agree that this Cookies Policy, together
            with our Privacy Policy and Terms, applies to that use.
            We are committed to protecting you and any information (anonymous or otherwise) that
            we collect about you online. This section tells you about how and why we use cookies,
            and how this allows us to improve our service.
            If you wish to use our website, but would like us not to set cookies in your browser, you
            can disable or remove the cookies. Details on how to do this are set out in sections
            below. Please note that disabling or removing the cookies we or our third party service
            providers set may impact the functionality and security of our website and our ability to
            provide our services to you. Please note that you will not be able to use our website or
            the associated services if you do not accept our use of cookies.
            We reserve the right to make changes to our Cookies Policy. Any changes we may
            make to our Cookies Policy in the future will be posted on this page and, where
            appropriate, notified to you by email. Please check back frequently to see any updates
            or changes to our Cookies Policy. Your continued use of our website is taken as your
            agreement to any such changes.
            </p>

            <h2>About cookies</h2>
            <p>
            Cookies are files containing small amounts of information which are downloaded to the
            device you use when you visit a website. Your web browser (such as Internet Explorer,
            Mozilla Firefox, Google Chrome or Safari) then sends these cookies back to our website
            or application on each visit. Cookies do not contain any information that personally
            identifies you.
            We and our reputable third party service providers use the information we obtain about
            you through the use of cookies for the following purposes to:
            (a) recognise your device(s) when you visit our website;
            (b) remember if you’ve set any preferences on our website;
            (c) temporarily store data as part of, for example, customer surveys;
            (d) test new content and evaluate its effectiveness;
            (e) remember if you’ve been informed of our Cookies Policy, Privacy Policy and other
            Terms;
            (f) track you as you navigate our website (including tracking page usage and paths used
            by visitors through our website; and tracking use of our Internet banner advertisements
            and other links from our marketing partners’ websites to our website);
            (g) improve our website’s usability;
            (h) allow you to share pages with social networks such as LinkedIn, Facebook,
            Instagram and Twitter / to support social media components, like Facebook or Twitter
            (where our website uses a plugin from these third party platforms);
            (i) ensure you don’t miss information that is relevant to you (including targeting our
            website content, targeting our marketing campaigns and direct marketing emails,
            targeting our Internet banner advertisements on our website and on other websites);
            (j) analyze use of our website (including for statistical analysis, sales and marketing
            research). To stop us setting these cookies you will need to set up your browser to
            reject all cookies (see further below).
            We may also combine this web usage data with other information we have collected
            about you. We store this information so that we have a better and more specific
            understanding of the way users are using our website, and their preferences and
            interests, for the purposes set out above.
            Cookies may be either “persistent” cookies or “session” cookies, depending on how long
            they are used.
            Persistent cookies remain on your device after you have closed your browser, and allow
            a website to remember your actions and preferences. They are activated each time you
            visit the website where the cookie was generated. Sometimes persistent cookies are
            used by websites to provide targeted advertising based on the browsing history of the
            device. They are stored by the browser and remain valid until their set expiry date
            (unless deleted by the user before the expiry date).
            Session cookies only last for the duration of your visit and are deleted when you close
            your browser. They facilitate tasks such as allowing a website to identify that a user of a
            particular device is navigating from page to page, supporting website security or basic
            functionality.
            Many of the cookies we use are session cookies. For example, they help us to ensure
            the security of your session, and can also keep you signed in to your Muvr account
            while you move between pages.
            </p>

            <h2>More about the cookies we use</h2>
            <p>
            Whether a cookie is a first or third party cookie depends on which website the cookie
            comes from. First party cookies are those set by or on behalf of the website visited. All
            other cookies are third party cookies. We use both first party and third party cookies.
            You can read more about them below. Third party cookies: In some cases, some of
            these cookies are managed for us by third parties, but we don’t allow the third party to
            use the cookies for any purpose other than those listed above. Strictly necessary
            cookies
            These are cookies that are required for the operation of our website. They include, for
            example, cookies that enable you to log into secure areas of our website such as your
            Clear Score account.
            </p>

            <h2>Performance cookies.</h2>
            <p>
            These allow us to recognise and count the number of visitors and to see how visitors
            move around our website when they are using it. This helps us to improve the way our
            website works, for example, by ensuring that users are finding what they are looking for
            easily.
            </p>

            <h2>Functionality cookies.</h2>
            <p>
            These are used to recognise you when you return to our website. This enables us to
            personalize our content for you, greet you by name and remember your preferences (for
            example, your choice of language or region).
            </p>

            <h2>Analytics cookies.</h2>
            <p>
            In order to keep our products and services easy to use and up-to-date, we use web
            analytics services to help us understand how people use our website. For example we
            can see which parts of our services are most popular, identify when errors occur and
            test different versions of a page or feature to see which one works best. These web
            analytics services may be designed and operated by other companies on our behalf.
            They do this using small invisible images known as “web beacons” or “tracking pixels”
            that may be included in the digital products and services on our website. These are
            used for example to count the number of times something has been seen. These web
            beacons are anonymous and do not contain or collect any information that identifies
            you.
            </p>



        </section>
    </div>
  )
}

export default PrivacyPolicy