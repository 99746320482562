//import { Button } from 'bootstrap'
import React from 'react'
//import '../App.css'
import './HeroSection.css'
import { Button } from './Button';
import Navbar from './Navbar';

function HeroSection() {
  return (
    <div className='hero-container' >
        {/* <video src="/videos/video.mp4" autoPlay loop muted controls /> */}
        {/* <video autoPlay muted loop>
            <source src="/videos/video.mp4" type="video/mp4" ></source>
        </video> */}
        <Navbar/>
        {/* <h1 >ON DEMAND TRUCK</h1> */}
        {/* <h2>Revoultionizing the logistics industry</h2> */}
        <h3>ON DEMAND TRUCK</h3>
        <p>MOVE.DELIVER.HAUL</p>
        <div className='hero-btns'>
            {/* <Button 
            className='btns'
            buttonStyle='btn--outline' 
            buttonSiize='btn--large'>
            GET STARTED
            </Button> */}
            {/* <button onclick="location.href='http://www.google.com';" type="button" className="button">GET STARTED</button> */}
            <a  target="_blank"  rel="noreferrer" href='https://play.google.com/store/apps/details?id=co.za.muvr'>
               <img alt="googleplay" src="images/play.google.comstore-800x238.png" className='img_google' ></img>
            </a>
            <a  href='/' rel="noreferrer">
               <img alt="applestore" src="images/apple_comingsoon.png" className='img_google' ></img>
            </a>
            
            {/* <a className="button" target="_blank"  href='https://play.google.com/store/apps/details?id=co.za.muvr'>DOWNLOAD APP</a> */}
            {/* <Button 
            className='btns'
            buttonStyle='btn--primary' 
            buttonSiize='btn--large'>
            WATCH VIDEO
            </Button> */}
            {/* <button className="button" onclick="location.href='http://www.example.com'">WATCH VIDEO</button> */}
            {/* Working */}
            {/* <button className="button" onclick="window.open('documents/muver.pptx')">WATCH VIDEO</button> */}

            {/* --Removed as a result not to show our face */}
            {/* <a className="button" href='documents/muver.pptx' download="Muvr Presentation">READ PROPOSAL</a> */}
        </div>
    </div>
  )
}

export default HeroSection