import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from './Button';
import './Navbar.css'

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false)
        } else {
            setButton(true)
        }
    };

    useEffect(()=> {
        showButton()
    },[])

    window.addEventListener('resize', showButton)

  return (
      <>
      <nav className='navbar'>
          <div className='navbar-container'>
              <Link to="/" className="nav-bar-logo" onClick={closeMobileMenu}>
                  {/* MUVR  */}
                  {/* Muvr <i className="fab fa-typo3"></i> */}
                  <img src="images/muvr_png1.png" alt="Muvr"></img>
              </Link>
              <div className='menu-icon' onClick={handleClick}>
                {/* {click ? 'fa fa-times': 'fa fa-bars'} */}
                <i className={click ? 'fa fa-times': 'fa fa-bars'}/>
              </div>
              <ul className={click ? 'nav-menu active': 'nav-menu'}>
                  <li className='nav-item'>
                      <Link to='/' className='nav-links' onClick={closeMobileMenu}>Home</Link>
                  </li>
                  {/* <li className='nav-item'>
                      <Link to='/blog' className='nav-links' onClick={closeMobileMenu}>Blog</Link>
                  </li> */}
                  <li className='nav-item'>
                      <Link to='/about' className='nav-links' onClick={closeMobileMenu}>About</Link>
                  </li>
                  {/* <li className='nav-item'>
                      <Link to='/team' className='nav-links' onClick={closeMobileMenu}>Team</Link>
                  </li> */}
                  
                  <li className='nav-item'>
                      <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>Contact Us</Link>
                  </li>
                  {/* <li className='nav-item'>
                      <Link to='/login' className='nav-links-mobile' onClick={closeMobileMenu}>Log In</Link>
                  </li> */}
                  {/* <li className='nav-item'>
                      <Link to='/register' className='nav-links-mobile' onClick={closeMobileMenu}>Register</Link>
                  </li> */}
              </ul>
              {/* {button && <Button buttonStyle='btn--outline'>REGISTER</Button>} */}
          </div>
      </nav>
      </>
  )
}

export default Navbar