import React, {useState, useEffect} from 'react';
import fireDb from "../firebase";
import {Link} from "react-router-dom";
import "./Home.css";
import HeroSection from '../components/HeroSection';
import Person from '../components/Person';
import Contact from './Contact';
// --old was trying some things import AddEdit from './AddEdit-re';
import AddEdit from './AddEdit';
import Footer from '../components/Footer';
import About from './About';
import Cards from '../components/Cards';


const Home = () => {
    
    const [data, setData] = useState({});

    useEffect(() => {
        fireDb.database().ref().child("contacts").on("value",(snapshot)=>{
            if(snapshot.val()!== null){
                setData({...snapshot.val()})
                console.log("snapshot" + snapshot.val())
            }
            else{
                setData({});
            }
        });
        return () => {
            setData({})
        };
    },[]);

    return (
        <div>
            <HeroSection/>
            <h1>About</h1>
            {/* <div className='boxit'>
            Muvr was founded in 2021, born from an idea that revolutionizes the way moving solutions are offered and solving the last mile delivery problems.
            <br/>
            Muvr founders Renaldo Swartz and Tochukwu Oba created this application for people to interact and move things from everyday household and office furniture and items, farm produce, construction materials and so forth from point A to B. We seek to
            enable affordable, reliable, safe ,timely  and seamless move.
            <br/>
            Muvr further strives towards bridging the economic gaps by using the platform to promote entrepreneurship and provide employment for truck drivers registered on the platform and at the same time alleviating stress of moving where a truck is not readily available.
            </div> */}

            <div className='description'>
                Muvr is an on-demand logistics app that connects truck/ bakkie drivers with users to enable them transport light to medium heavy items. We at Muvr are creating an ecosystem that makes it easier, safer and affordable to transport your parcels, goods and furniture, garden items, farm produce by using our easy-to-use mobile app.
            </div>

            {/* <h2 className="heading_boxi">Team</h2> */}
            {/* <Person name="Renaldo Swartz" imagename="renaldo"  role="Co-Founder and Chief Operations Officer" /> */}
            {/* <Person name="Tochukwu Oba" imagename="tochukwu" role="Co-Founder and Chief Technical Officer" /> */}
            {/* <Cards/> */}
            <AddEdit/>
            {/* <Footer/> */}

        </div>
    )
}

export default Home

