import React from 'react';
import './Failed.css'

const FailedPage = () => {
    return (
      <div className="success-page mt-5">
        <h1 className="text-center text-white">Your payment failed</h1>
        <p className="text-center text-white">
        We're sorry, but your payment was not successful. Please double check your payment information and try again.
        </p>
        <p className="text-center text-white">
          If you have any questions or concerns, please don't hesitate to contact us at info@muvr.co.za.
        </p>
        <p className="text-center text-white">
          Try Again
        </p>
      </div>
    );
  }
  
  export default FailedPage;