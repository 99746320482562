import React from 'react'

const View = () => {
    return (
        <div style={{ marginTop: "150px"}}>
            <div className= "card">
                <div className= "card">
                    <p>User contact Detail</p>
                </div>
                <div className= "container">
                   <strong>ID:</strong>
                   {/* <span>{id}</span> */}
                   <br />
                   <br />

                   <strong>Name:</strong>
                   {/* <span>{user.name}</span> */}
                   <br />
                   <br />

                   <strong>Email:</strong>
                   {/* <span>{user.email}</span> */}
                   <br />
                   <br />

                   <strong>Contact:</strong>
                   {/* <span>{user.contact}</span> */}
                   <br />
                   <br />

                   {/* <Link to="/">
                    <button className="btn btn-edit">Go Back</button>
                   </Link> */}
                </div>
            </div>
        </div>
    )
}

export default View
