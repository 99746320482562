import React from 'react';
import './Success.css'

const SuccessPage = () => {
    return (
      <div className="success-page mt-5">
        <h1 className="text-center text-white">Thank you for using Muvr!</h1>
        <p className="text-center text-white">
          Your booking has been confirmed and we are looking forward to assisting you with your Muv.
          Please check your email for further details and instructions.
        </p>
        <p className="text-center text-white">
          If you have any questions or concerns, please don't hesitate to contact us at info@muvr.co.za.
        </p>
        <p className="text-center text-white">
          Thank you again for choosing Muvr for your muv.
        </p>
      </div>
    );
  }
  
  export default SuccessPage;