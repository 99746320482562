import React,{useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import './AddEdit.css';
import fireDb from "../firebase";
import {toast} from "react-toastify";
// import { Navbar } from 'react-bootstrap';
import Navbar from '../components/Navbar';
//import "firebase/compat/database";
import Footer from '../components/Footer';

const initialState = {
    name: "",
    email: "",
    contact: "",
    message: "",
}

const AddEdit = () => {
    const [state,setState] = useState(initialState);
    //const [data, setData] = useState({});

    const {name, email, contact, message} = state;

    const history = useHistory();

    const handleInputChange = (e) => {
        const{name, value} = e.target;
        console.log(name,value)
        setState({...state,[name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // So it doesn't have to refresh
        if(!name || !email || !contact || !message){
            toast.error("Please provide value in each input field")
        }
        else{
            //fireDb.database().ref().child("contacts")
            fireDb.database().ref().child("contacts").push(state, (err) =>{
                if(err){
                    toast.error(err);
                }
                else{
                    toast.success("Contact Added Successfully")
                    console.log("Contact Added Successfully")
                }
            });
            setTimeout(()=> history.push("/"),500);
        }
    };

    
    return (
        <div style={{marginTop: "100px"}}>
            <Navbar/>
            <h2 className='contact'>Contact</h2>
            <p className='contact-description'>We would like to hear from you. Send us a message</p>
            <form 
                style={{
                    margin: "auto", 
                    padding: "15px", 
                    maxWidth: "800px", 
                    alignContent: "center", 
                    }}
                    onSubmit={handleSubmit}
                    >
                {/* The challenge with the display of the name  */}
                <label htmlFor = "name">Name</label>    
                <input type="text" id="name" name="name" placeHolder="Your Name ..." value={name} onChange={handleInputChange}/>
                <label htmlFor = "name">Email</label>    
                <input type="email" id="email" name="email" placeHolder="Your Email ..." value={email} onChange={handleInputChange}/>   	
                <label htmlFor = "name">Phone Number</label>    
                <input type="number" id="contact" name="contact" placeHolder="Your Phone No ..." value={contact} onChange={handleInputChange}/> 
                <label htmlFor = "name">Message</label>    
                <textarea type="text" id="message" name="message" placeHolder="Your Message No ..." value={message} onChange={handleInputChange}/>   

                <input type="submit" value="Send Message"/>  	

            </form>
            <Footer/>
        </div>
    )
}

export default AddEdit
