import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import db from '../firebase_upgrade'
import { ref, get, remove, child} from 'firebase/database'
// import fireDb from "../firebase";
// import { ref, set, get, update,  remove, child, onValue } from 'firebase/database'

function Trips() {
    const [data, setData] = useState({});

    console.log(data);
    //console.log(setData);


    // useEffect(() => {
    //     fireDb.database().ref("contacts").on("value",(snapshot)=>{
    //         if(snapshot.val()!== null){
    //             setData({...snapshot.val()})
    //             console.log("snapshot" + snapshot.val())
    //             //navigate('/')
    //         }
    //         else{
    //             setData({});
    //         }
    //         // const drivers = snapshot.val();
    //         // const driverList = []
            
    //         // for (let id in drivers){
    //         //     driverList.push({id, ...drivers[id]});
    //         // }

    //         // setData(driverList);
    //         // console.log(driverList)

    //     });
    //     return () => {
    //         setData({})
    //     };
    // },[]);

    

    useEffect(() => {

        console.log(db.db)
        const dbRef = ref(db.db);
        get(child(dbRef, 'Ride Requests/')).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            const drivers = snapshot.val();
            const driverList = [];
            for (let id in drivers){
                driverList.push({ id,  ...drivers[id] });
            }
            setData(driverList);
            console.log(driverList);
        } else {
            console.log("No data available");
        }
        }).catch((error) => {
        console.error(error);
        });

        //deleteData(11)
        //confirmData('S01Xg3OB2tOesOWmnvU0OmkUYhN2')

        // const driverRef = fireDb.database().ref("drivers");
        // driverRef.on("value", (snapshot) =>{
        //     const drivers = snapshot.val();
        //     const driverList = [];
        //     for (let id in drivers){
        //         driverList.push({ id,  ...drivers[id] });
        //     }
        //     setData(driverList);
        //     console.log(driverList);
        // });
    }, [])

    // const updateData = (key) =>
    // {
    //     //const db = this.state.db;
    //     const data = this.getAllInputs();

    //     console.log("This is the key"+ key)
    
    //     update(ref(db.db, 'drivers/'+ key + '/' + data.username),{
    //         Firstname: data.firstname,
    //         Lastname: data.lastname,
    //         Phone: data.phonenumnber
    //     }).then(()=>{alert('data was updated successfully')})
    //     .catch((error)=>{alert("there was an error, details: " + error)});
    // }

    // const confirmData = (key) =>
    // {
    //     //const db = this.state.db;
    //     //const data = this.getAllInputs();

    //     console.log("This is the key"+ key)
    
    //     set(ref(db.db, 'drivers/'+ key),{
    //         status: "confirmed",
    //     }).then(()=>{alert('data was set confirmed successfully')})
    //     .catch((error)=>{alert("there was an error, details: " + error)});
    // }

    const deleteData = (key) =>
    {
        //const db = this.state.db;
        //const data = this.getAllInputs();

        console.log("This is the key"+ key)
    
        remove(ref(db.db, 'drivers/'+ key))
        .then(()=>{alert('data was deleted successfully')})
        .catch((error)=>{alert("there was an error, details: " + error)});
    }

  return (
      
    // <div>{driverList ? Drivers.map((driver)=> <Driver driver={driver} />)  :""}</div>
    
    <div style={{marginTop: "100px"}}>

        <table className='styled-table'>
            <thead>
                <tr>
                    <th style={{textAlign: "center"}}>No.</th>
                    <th style={{textAlign: "center"}}>Drivers Name</th>
                    <th style={{textAlign: "center"}}>Drivers Phone No</th>
                    <th style={{textAlign: "center"}}>Payment Method</th>
                    <th style={{textAlign: "center"}}>Trip Price</th>
                    <th style={{textAlign: "center"}}>Riders Name</th>
                    <th style={{textAlign: "center"}}>Riders Phone</th>
                    <th style={{textAlign: "center"}}>Pickup Addr</th>
                    <th style={{textAlign: "center"}}>Drop Off Addr</th>
                    <th style={{textAlign: "center"}}>Truck Selected</th>
                    <th style={{textAlign: "center"}}>Created At</th>
                    <th style={{textAlign: "center"}}>Status</th>
                    <th style={{textAlign: "center"}}>Action</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map((id,index) => {
                    return(
                        <tr key={id}>
                            <th scope="row">{index + 1}</th>
                            <td>{data[id].driver_name}</td>
                            <td>{data[id].driver_phone}</td>
                            <td>{data[id].payment_method}</td>
                            <td>{data[id].fares}</td>
                            <td>{data[id].rider_name}</td>
                            <td>{data[id].rider_phone}</td>
                            {/* <td>{data[id].pickup.latitude}</td> */}
                            <td>{data[id].pickup_address}</td>
                            <td>{data[id].dropoff_address}</td>
                            <td>{data[id].truck_selected}</td>
                            <td>{data[id].created_at}</td>
                            <td>{data[id].status}</td>
                            <td>
                                <Link to={`/update/${id}`}>
                                <button className='btn btn edit'>Edit</button>
                                </Link>
                                <button className='btn btn delete' onClick={()=>{}}>Switch</button>    
                                <button className='btn btn delete' onClick={()=>{deleteData(data[id].id)}}>Delete</button>
                                <Link to={`/delete/${id}`}>
                                <button className='btn btn view'>View</button>
                                </Link>    
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>

        <div></div>
    </div>
  )
}

export default Trips