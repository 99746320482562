import {  getApps,initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth"

// const firebaseConfig = {
//     apiKey: "AIzaSyAWpCG-b7OHcOwVjOKY-9X9BWaSMYyRDJ8",
//     authDomain: "muvr-eb7de.firebaseapp.com",
//     databaseURL: "https://muvr-eb7de-default-rtdb.firebaseio.com",
//     projectId: "muvr-eb7de",
//     storageBucket: "muvr-eb7de.appspot.com",
//     messagingSenderId: "650636356158",
//     appId: "1:650636356158:web:e996d8942a690cc1d87f6f",
//     measurementId: "G-PS9WZWK9PH"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyAWpCG-b7OHcOwVjOKY-9X9BWaSMYyRDJ8",
  authDomain: "muvr-eb7de.firebaseapp.com",
  databaseURL: "https://muvr-eb7de-default-rtdb.firebaseio.com",
  projectId: "muvr-eb7de",
  storageBucket: "muvr-eb7de.appspot.com",
  messagingSenderId: "650636356158",
  appId: "1:650636356158:web:e996d8942a690cc1d87f6f",
  measurementId: "G-PS9WZWK9PH"
};

const app = initializeApp(firebaseConfig);
console.log(getApps().length)

// if (getApps().length < 1) {
//   initializeApp(firebaseConfig);

// // export default {auth, db};
//   // Initialize other firebase products here
// }

const auth = getAuth(app)

const db = getDatabase(app)

// function StartFirebase(){
//   const firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID,
//     measurementId: process.env.REACT_APP_MEASUREMENT_ID
//   };

//   const app = initializeApp(firebaseConfig);

//   return getDatabase(app)

// }




//export default firebase
//export default {StartFirebase, auth};


//export default {StartFirebase};
export default{auth, db};

// !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();




