import React, {useState, useEffect} from 'react';
import fireDb from "../firebase";
import {signInWithEmailAndPassword} from "firebase/auth"
import {Link} from "react-router-dom";
import {useHistory} from 'react-router-dom'
import db from '../firebase_upgrade'
import auth from '../firebase_upgrade';
import "./Login.css";

const Login = () => {
    const [data, setData] = useState({
        email: '',
        password: '',
    });

    const history = useHistory()

    const {firstname, lastname, username, email, password, password2} = data

    useEffect(() => {
        fireDb.database().ref().child("contacts").on("value",(snapshot)=>{
            if(snapshot.val()!== null){
                setData({...snapshot.val()})
            }
            else{
                setData({});
            }
        });
        return () => {
            setData({})
        };
    },[]);

    const OnChange = (e) => {
        const{name, value} = e.target;
        console.log(name,value)
        //setFormData({...formData,[name]: value});
        setData((prevState) =>({
          ...prevState,
          [e.target.name]: e.target.value}));
      }

    const onSubmit = e => {
        e.preventDefault()

        signInWithEmailAndPassword(auth.auth,email,password)
        .then((cred)=> {
            console.log('user logged in:',cred.user)
            //navigate('/')
            history.push('/')
        })
        .catch((err)=>{
            console.log('error',err.message)
        })
    }

    return (
        <div>
            <section>
                <h2>Login</h2>
                <p>Sign In Now</p>
            </section>

            <section>
                <form onSubmit={onSubmit}>

                    <div className='form-group'>
                    <label htmlFor = "email">Email</label>   
                    <input 
                        type="text" 
                        className='form-control'
                        id="email"
                        value={email}
                        name="email"
                        placeHolder="Your Email ..." 
                        onChange={OnChange} 
                        />
                    </div>


                    <div className='form-group'>
                    <label htmlFor = "password">Password</label>   
                    <input 
                        type="password" 
                        className='form-control'
                        id="password"
                        value={password}
                        name="password"
                        placeHolder="Enter Password" 
                        onChange={OnChange} 
                        />
                    </div>

                    <div className='form-group'>
                        <button type='submit' className='btn btn-block'>
                        Login
                        </button>
                    </div>
                    {/* <input type="submit" value="Submit"/>  	 */}
                </form>
            </section>
        </div>
    )
}

export default Login

