import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
  return (
    <div className='cards'>
        <h1>What we have Learn't from Trucks</h1>
        <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem
                    src="images/tochukwu.jpg"
                    text="Explore the truck that things done"
                    label='Truck'
                    path='/blog'/>
                     <CardItem
                    src="images/tochukwu.jpg"
                    text="Explore the truck that things done"
                    label='Truck'
                    path='/blog'/>
                     <CardItem
                    src="images/tochukwu.jpg"
                    text="Explore the truck that things done"
                    label='Truck'
                    path='/blog'/>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Cards