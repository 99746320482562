import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import "./Header.css"
import auth from '../firebase_upgrade';
import fireAuth from "../firebase";
// import { Button } from 'bootstrap';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const Header = () => {
    const[activeTab, setActiveTab] = useState("Home");
    const location = useLocation();
    const [online, setOnline] = useState(false);
    // const [click, setClick] = useState(false);
    // const [button, setButton] = useState(true);

    // const handleClick = ()=> setClick(!click);
    // const closeMobileMenu = () => setClick(false)
    // const showButton = () => {
    //     if(window.innerWidth <= 960){
    //         setButton(false)
    //     }else{
    //         setButton(true)
    //     }
    // }

    useEffect(() => {
        if (location.pathname === "/"){
            setActiveTab("Home")
        } 
        else if (location.pathname === "/add"){
            setActiveTab("AddDriver")
        } 
        else if (location.pathname === "/about"){
            setActiveTab("About")
        } 
        else if (location.pathname === "/login"){
            setActiveTab("Login")
        } 
        else if (location.pathname === "/register"){
            setActiveTab("Register")
        } 
    },[location])

    //const online = false

    onAuthStateChanged(auth.auth, (user)=>{
        if (user){
            //online = true
            setOnline(true)
            console.log("user is signed in")
        }
        else{
            setOnline(false)
            console.log("user is signed out")
        }
    })

    return (
        <div className= "header">
            <div className="logo">
                <Link to="/" className="nav-bar-logo logo">
                    {/* Muvr App */}
                    {/* https://www.w3schools.com/css/css_inline-block.asp */}
                    {/* https://www.w3schools.com/css/tryit.asp?filename=trycss_inline-block_nav */}
                    {/* <img src="/assets/images/logo/logo.png" alt="Digital Agency"></img> */}
                    <img src="images/muvr_png1.png" alt="Muvr"></img>
                </Link>
            </div>
            
            <div className="header">
                <Link to="/">
                    <p className={`${activeTab === "Home" ? "active":""}`}
                        onClick={()=> setActiveTab("Home")}>
                        Home
                    </p>
                </Link>
                
                <Link to="/about">
                    <p className={`${activeTab === "About"? "active":""}`}
                        onClick={()=> setActiveTab("About")}>
                        About
                    </p>
                </Link>

                <Link to="/team">
                    <p className={`${activeTab === "Team"? "active":""}`}
                        onClick={()=> setActiveTab("Team")}>
                        Team
                    </p>
                </Link>

                <Link to="/contact">
                    <p className={`${activeTab === "AddDriver"? "active":""}`}
                        onClick={()=> setActiveTab("AddDriver")}>
                        Contact Us
                    </p>
                </Link>

                {
                    online ? (
                        <Link to="/">
                            <p onClick={()=> signOut(auth.auth)
                                .then(()=>{
                                    console.log("User successfully logged out")
                                })
                                .catch((err)=>{
                                    console.log(err.message)
                                })}>
                                Logout
                            </p>
                            {/* <Button>Logout</Button> */}
                        </Link>
                        //<Button>Logout</Button>
                    ) : (
                        <>
                        <Link to="/login">
                                <p className={`${activeTab === "Login"? "active":""}`}
                                    onClick={()=> setActiveTab("Login")}>
                                    Login
                                </p>
                        </Link>
                        <Link to="/register">
                            <p className={`${activeTab === "Register"? "active":""}`}
                                onClick={()=> setActiveTab("Register")}>
                                Register
                            </p>
                        </Link>
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default Header
