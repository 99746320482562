// import logo from './logo.svg';

import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom';
//import './App.css';
import About from "./pages/About";
import AddEdit from "./pages/AddEdit";
import Home from "./pages/Home";
import View from "./pages/View";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import Header from './components/Header';
import Login from "./pages/Login"
import Register from "./pages/Register"
import Drivers from './pages/Drivers';
import Users from './pages/Users';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Team from "./pages/Team";
// import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from './components/Navbar';
import Trips from './pages/Trips';
import UpdateDriver from './pages/UpdateDriver';
import TermsandCondition from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SuccessPage from './pages/Success';
import FailedPage from './pages/Failed';
import CancelledPage from './pages/Cancelled';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      {/* <Header/> */}
      {/* <Navbar/> */}
      <ToastContainer position="top-center" />
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/contact" component={AddEdit}/>
        <Route exact path="/login" component={Login}/>      
        <Route exact path="/register" component={Register}/>
        <Route exact path="/update/:id" component={UpdateDriver}/>
        <Route exact path="/view/:id" component={View}/>
        <Route exact path="/about" component={About}/>
        {/* <Route exact path="/team" component={Team}/> */}
        {/* <Route exact path="/driver" component={Drivers}/> */}
        <Route exact path="/trips" component={Trips}/>
        <Route exact path="/user" component={Users}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/dashboard" component={Dashboard}/>
        <Route exact path="/profile" component={Profile}/>
        <Route exact path="/termsandconditions" component={TermsandCondition}/>
        <Route exact path="/privacypolicy" component={PrivacyPolicy}/>
        <Route exact path="/success" component={SuccessPage}/>
        <Route exact path="/failed" component={FailedPage}/>
        <Route exact path="/cancelled" component={CancelledPage}/>


      </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
