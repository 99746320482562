import React, {useState, useEffect, Component} from 'react'
import StartFirebase from '../firebase_upgrade'
import db from '../firebase_upgrade'
import { ref, set, get, update,  remove, child, onValue } from 'firebase/database'
import 'firebase/database'
import {Table} from 'react-bootstrap'
import "./Contact.css";
import Navbar from '../components/Navbar'

//const db = StartFirebase;

// export default function Contact() {

//     const[data, setData] = useState({});

//     const contact = ref(db, 'contacts/');
//     onValue(contact, (snapshot)=>{
//         const data = snapshot.val();
//         console.log(data)
//     })

//     useEffect(()=> {
//         //var contact = db.ref('contact/')
//         //StartFirebase.child.ref("driver")
//         contact.on("value",(snapshot)=>{
//             if(snapshot.val()!== null){
//                 setData({...snapshot.val()})
//             }
//             else {
//                 setData({});
//             }
//         });
//         return () => {
//             setData({});
//         }
//     },[]);

//   return (
//     <Table>
//         <thead>
//             <tr>
//                 <th>#</th>
//                 <th>FirstName</th>
//                 <th>LastName</th>
//                 <th>Phone</th>
//                 <th>Email</th>
//             </tr>
//         </thead>
//         <tbody>
//             {Object.keys(data).map((id, index) => {
//                 return (
//                     <tr key={id}>
//                         <th scope="row">{index +1}</th>
//                         <td>{data[id].fname}</td>
//                         <td>{data[id].lname}</td>
//                         <td>{data[id].phone}</td>
//                         <td>{data[id].email}</td>
//                     </tr>

//                 )
//             })}
            
//         </tbody>
//     </Table>
//   )
// }



export class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            db: '',
            firstname: '',
            lastname: '',
            phonenumnber: '',
            //dob: ''
        }

        this.interface = this.interface.bind(this)
    }

    componentDidMount(){
        this.setState({
            db: StartFirebase
        })
    }

  render() {
    return (
     <>
        <label>Enter FirstName</label>
        <input type='text' id='firstuserbox' value={this.state.firstname} onChange={e => {this.setState({firstname:e.target.value})}}/>
        <br/><br/>

        <label>Enter LastName</label>
        <input type='text' id='lastuserbox' value={this.state.lastname} onChange={e => {this.setState({lastname:e.target.value})}}/>
        <br/><br/>

        <label>Enter Phone</label>
        <input type='text' id='phonebox' value={this.state.phonenumnber} onChange={e => {this.setState({phone:e.target.value})}}/>
        <br/><br/>

        <button id="addBtn" onClick= {this.interface}>Add Data</button>
        <button id="updateBtn" onClick= {this.interface}>Update Data</button>
        <button id="deleteBtn" onClick= {this.interface}>Delete Data</button>
        <button id="selectBtn" onClick= {this.interface}>Get Data from DB</button>

     </>
    )
  }

  interface(event){
      const id = event.target.id;

      if (id==='addBtn'){
          console.log("add")
          this.insertData();
      }

      else if(id==='updateBtn'){
        console.log("update")
          this.updateData();
      }

      else if(id==='deleteBtn'){
        console.log("delete")
        this.deleteData();
      }

      else if(id==='selectBtn'){
        console.log("select")
        this.getData();
      }
  }

  getAllInputs(){
      return {
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          phonenumnber: Number(this.state.phonenumber),
      }
  }

  insertData(){
    const db = this.state.db;
    const data = this.getAllInputs();

    set(ref(db, 'Customer/'+data.username),{
        Firstname: data.firstname,
        Lastname: data.lastname,
        Phone: data.phonenumnber
    }).then(()=>{alert('data added successfully')})
    .catch((error)=>{alert("therer wasn error, details: " + error)});
  }

  updateData(){
    const db = this.state.db;
    const data = this.getAllInputs();

    update(ref(db, 'Customer/'+data.username),{
        Firstname: data.firstname,
        Lastname: data.lastname,
        Phone: data.phonenumnber
    }).then(()=>{alert('data was updated successfully')})
    .catch((error)=>{alert("there was an error, details: " + error)});
  }

  deleteData(){
    const db = this.state.db;
    const username = this.getAllInputs().username;

    remove(ref(db, 'Customer/'+username))
    .then(()=>{alert('data was deleted successfully')})
    .catch((error)=>{alert("there was an error, details: " + error)});
  }

  selectData(){
      const dbref = ref(this.state.db);
      const username = this.getAllInputs().username;

      get(child(dbref, 'users' + username))
      .then((snapshot)=>{
          if(snapshot.exists()){
              this.setState({
                firstname: snapshot.val().Firstname,
                lastname: snapshot.val().Lastname,
                phonenumnber: snapshot.val().Phone,
              })
          }
          else{
              alert('no data found!')
          }
      })
      .catch((error)=>{alert("there was an arror, details " + error)});
  }

  getData(){
    console.log(db.db)
    const dbRef = ref(db.db);
    get(child(dbRef, 'drivers/')).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }
}

export default Contact
