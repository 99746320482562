import React from 'react'

function Terms() {
  return (
    <div>
        <section>
            <h1>Terms and Conditions</h1>
            <p>It is important to read these terms and conditions. Muvr provides a service between a
            customer and a driver, to transport your items by way of its Application and website . By
            using the Application you confirm that you have read and accepted these Terms and
            Conditions. If you do not agree to these Terms and Conditions or any amendment or
            addition thereto do not continue using the Application or Muvr service. Using this
            service constitutes an agreement between you and Muvr (Proprietary) Limited
            (registration number: 2021/661407/07). By using the Application, or downloading,
            installing or using any associated software to enable the service, you hereby expressly
            acknowledge and agree to be bound by these Terms and Conditions, and any future
            amendments and additions to these Terms and Conditions.</p>

            <p>Muvr facilitates the transportation service between drivers and customers, but does not
            intend to provide the actual transportation services or act in any way as a transport
            provider to you, and takes no responsibility for any transportation provided to you.</p>

            
            <h2>License to use the Muvr Application and Intellectual property</h2>
            <p>
            Muvr gives you a non-exclusive non-transferable license to access and use the
            Application solely for the purpose of using the Services provided by Muvr. You
            may not use any intellectual property from any portion of the Application nor
            modify or reproduce any part thereof. The services available on the Application
            shall remain the property of Muvr.
            </p>

            <h2>Services</h2>
            <p>
            The Application allows you to obtain transportation services from a driver. If a
            driver accepts your request, you will be notified with the details of the service to
            be provided.
            </p>

            <h2>Warranties</h2>
            <p>
            You acknowledge and agree that:
            Muvr only acts as a facilitator between you and a driver.Muvr does not provide
            transportation services, and shall not be held liable for the actions or inactions of
            a driver provided through the Application. The contract for the provision of
            transportation services is between you and the driver. Muvr is not a party to any
            agreement entered into between you and a driver. All disputes arising from the
            transportation services shall be between you and the driver.
            Should another person use your login details for use of the Application, it shall be
            assumed that the person is you.
            Muvr does not warrant that the Application will function with any particular
            hardware, software or device. In addition, the Application may be subject to
            malfunctions and delays due to Internet connections and electronic
            communications.
            </p>

            <p>
            Muvr will not be responsible for any direct, indirect or consequential loss
            whatsoever and howsoever caused, arising out of or in connection with the use
            of the Application or any information available on the Application.
            You will not use the Application or the transportation services for any unlawful
            purpose, and you shall comply with all the laws of the Republic of South Africa
            when using the Application and acquiring the transportation services.
            Goods requiring transportation are covered by appropriate insurance provided by
            the driver and cover as well as liability is subjected to the cover. Muvr shall not be
            held responsible for any loss or damage occasioned to your goods.
            You will refrain from doing anything which would bring disrepute or damage to
            Muvr’s reputation.
            You will defend and indemnify Muvr against any and all claims or legal
            proceedings brought against Muvr and its affiliates, directors, other users,
            employees, arising in connection with any unlawful use or misuse of the
            Application.
            Muvr is not an agent, employee, employer or associate of the driver.
            You will provide accurate information, including banking details, as required for
            the Application service, failing to do so Muvr has the right to terminate this
            Agreement and your use of the Application at any time with or without notice.
            You agree that your use of the Application will be subject to Muvr Privacy Policy
            amended from time to time.
            </p>

            <h2>Personal data and Privacy Policy</h2>
            <p>
            All parties shall take steps to ensure that the use, storage and transfer of
            personal data is only as required for the purpose of these Terms and conditions,
            and all parties shall endeavor to comply with the Protection of Personal
            Information Act No 4 of 2013 (POPI) and to protect such Personal Information
            against unauthorized or unlawful disclosure, access, use, accidental loss,
            destruction or damage. Muvr shall not be responsible for any loss, destruction, alteration or disclosure of
            your data caused by any third party.
            You warrant that you are entitled to transfer the relevant personal data to the
            Application, so that Muvr can process, use and/or transfer the data in order to
            facilitate the transportation services.
            </p>

            <h2>Charges and Payment</h2>
            <p>
            Downloading Muvr is free for your android and IOS device.
            The estimated charges for the transportation services are available on the
            Application. The charges may be amended at Muvr’s sole discretion from time to
            time and without prior notice.
            Once you have provided details for the transportation required, an estimated
            charge will be generated by the Application. Once you accept the service, you
            will receive details of your driver. The charge to you shall include Muvr’s
            commission of 15% all prices are excluding VAT.
            The driver reserves the right to revise the charges for the transportation services
            in his sole discretion once he arrives at the pick-up point. Muvr will use
            reasonable efforts to inform you of charges that may apply. You will have the right
            to reject the updated charges, and you may be charged a cancellation fee of no
            more than 15% of the estimated charge.
            The final amount charged to you is intended to fully compensate the driver,
            including gratuities, for the transportation services provided.
            Charges paid by you are final and non-refundable, unless otherwise determined
            by Muvr.
            In the event that you elect cash as your preferred method of payment, you agree
            to make payment in full directly to the driver facilitated by the Application. All
            transportation charges are to be paid in full before any transportation services
            are undertaken, or as agreed between the driver and you.
            In the event that you elect electronic payment as your preferred method of
            payment through the Application, you agree for Muvr to debit your account
            immediately and for the final charges through the Application. All transportation
            charges are to be paid in full before any transportation services are undertaken.
            Your goods to be transported will be considered to be in the driver’s custody and
            control from the time of receipt of the goods until the time the goods are
            delivered. Any claims in this regard will be directly between you and the driver.
            </p>

            <h2>Disclaimer</h2>
            <p>
            The Muvr App is provided to you on an “as is” basis. Muvr does not guarantee
            that the Application is free from errors, defects, malware and viruses or that the
            Application is at all times correct, up to date and accurate. The drivers registered on the App may be tracked through the Application
            platform. The location tracking feature may not be error-free nor may it be
            accurate. Your use will be solely at your own risk and is solely for your
            convenience.
            </p>

            <h2>Governing law and jurisdiction</h2>
            <p>
            These terms and conditions shall be governed by and construed in accordance
            with the laws of the Republic of South Africa. By registering on the Application or
            using any services offered on the Application, you are deemed to have accepted
            and agreed to these terms and conditions.
            </p>

            <h2>Miscellaneous</h2>
            <p>
            Muvr Pty Ltd may, at its sole discretion and at any time elect to modify these
            terms and conditions or suspend or discontinue any of the services offered on
            the Application, or suspend or discontinue the Application.
            You may not assign or transfer any of your rights or obligations under these
            terms and conditions to any person without our prior written approval. Muvr may
            assign and transfer any of its rights and obligations to any person.
            If any provision of the Terms and Conditions is held to be unenforceable, such
            provision shall be null and void and the remaining provisions shall remain in full
            force and effect.
            The failure by Muvr to enforce any right or provision of the Terms and Conditions
            shall not constitute a waiver of such right.
            </p>



        </section>
    </div>
  )
}

export default Terms