import React from 'react'
import { Button } from './Button'
import './Footer.css'
import {Link, useLocation} from "react-router-dom";
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <div className='footer-container'>
        <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
                Join our Newsletter to recieve our latest update on our app
            </p>
            <p className='footer-subscription-text'>
                You can unsubscribe at any time
            </p>
            <div className="input-areas">
                <form>
                    <input type="email" name="email" placeholder=' Your Email' className="footer-input"/>
                    {/* <Button buttonStyle='btn--primary'>Subscribe</Button> */}
                    <button className="button">Subscribe</button>
                </form>
            </div>
        </section>
        <div className='footer-seperate'>
        <section>
        <div className='footer-links'>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>About Us</h2>
                    <Link to='/about'>How It Works</Link>
                    <Link to='/'>Testimonials</Link>
                    {/* <Link to='/'>Careers</Link>
                    <Link to='/'>Investors</Link>
                    <Link to='/'>Terms and Services</Link> */}
                </div>  
                <div className='footer-link-items'>
                    <h2>Company</h2>
                    <Link to='/about'>About Us</Link>
                    <Link to='/'>Blog</Link>
                    <Link to='/privacypolicy'>Privacy Policy</Link>
                    <Link to='/termsandconditions'>Terms and Conditions</Link>
                </div>
            </div>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>Helpful Links</h2>
                    <Link to='/about'>How It Works</Link>
                    <Link to='/'>FAQ</Link>
                    <Link to='/'>Careers</Link>
                    <Link to='/'>Investors</Link>
                    {/* <Link to='/termsandconditions'>Terms and  Conditions</Link>
                    <Link to='/privacypolicy'>Privacy Policy</Link> */}
                </div>  
                <div className='footer-link-items'>
                    <h2>Contact</h2>
                    
                    <Link to='/'>Address: 4095 Lily Court Westbury, Johannesburg 2093</Link>
                    <a href='tel:+27 83 580 7796'>Phone: +27 83 580 7796</a>
                    <a href='mailto:info@muvr.co.za'>Email: info@muvr.co.za</a>
                    <a href='mailto:support@muvr.co.za'>Support: support@muvr.co.za</a>
                    <a href='mailto:investor@muvr.co.za'>Investor: investor@muvr.co.za</a>
                    {/* <Link to='/'>Email: info@muvr.co.za</Link>
                    <Link to='/'>Support: support@muvr.co.za</Link>
                    <Link to='/'>Investor: investor@muvr.co.za</Link> */}
                </div>
            </div>
        </div>
        <section>
            <div>
                <div className='footer-logo'>
                    <div>
                    <Link className="social-logo" to='/'>
                        <img src="images/muvr_png2.png" alt="Muvr"></img>
                    </Link>
                    <small className='website-rights'> Muvr © 2022</small>
                    </div>
                
                    <div className='social-icons'>
                        {/* <Link className="social-icon-link facebook" to='/' target="_blank" aria-label='Facebook'><img src="images/facebook.png" target="_blank" alt=""/></Link> */}
                        {/* <Link className="social-icon-link facebook" to='https://www.facebook.com/muvrza' target="_blank" aria-label='Facebook'><FontAwesomeIcon icon={faFacebook}/></Link> */}
                        <a href="https://www.facebook.com/muvrza" className="social-icon-link facebook" target="_blank" rel="noreferrer" aria-label='Facebook'><FontAwesomeIcon icon={faFacebook}/></a>
                        {/* <Container>
                        <Link className="social-icon-link facebook" to='/' target="_blank" aria-label='Facebook'><FontAwesomeIcon icon={faFacebook}/></Link>
                        </Container> */}
                        {/* <Link className="social-icon-link instagram" to='/' target="_blank" aria-label='Instagram'><img src="images/instagram.png" target="_blank" alt=""/></Link> */}
                        <a href="https://www.instagram.com/muvrza" className="social-icon-link instagram" target="_blank" rel="noreferrer" aria-label='Instagram'><FontAwesomeIcon icon={faInstagram}/></a>
                        {/* <Link className="social-icon-link instagram" to='/https://www.instagram.com/muvrza/' target="_blank" aria-label='Instagram'><FontAwesomeIcon icon={faInstagram}/></Link> */}
                        {/* <Container>
                        <Link className="social-icon-link instagram" to='/' target="_blank" aria-label='Instagram'><FontAwesomeIcon icon={faInstagram}/></Link>
                        </Container> */}
                        {/* <Link className="social-icon-link twitter" to='/' target="_blank" aria-label='Twitter'><img src="images/twitter.png" target="_blank" alt=""/></Link> */}
                        <a href="https://www.twitter.com/muvrza" className="social-icon-link twitter" target="_blank" rel="noreferrer" aria-label='Twitter'><FontAwesomeIcon icon={faTwitter}/></a>
                        {/* --use this <Link className="social-icon-link twitter" to='/' target="_blank" aria-label='Twitter'><FontAwesomeIcon icon={faTwitter}/></Link> */}
                        {/* <Container>
                        <Link className="social-icon-link twitter" to='/' target="_blank" aria-label='Twitter'><FontAwesomeIcon icon={faTwitter}/></Link>
                        </Container> */}
                        {/* <Link className="social-icon-link linkedin" to='/' target="_blank" aria-label='Linkedin'><img src="images/twitter.png" target="_blank" alt=""/></Link> */}
                        {/* -- use this <Link className="social-icon-link linkedin" to='/' target="_blank" aria-label='Linkedin'><FontAwesomeIcon icon={faLinkedin}/></Link> */}
                        {/* <Container>
                        <Link className="social-icon-link linkedin" to='/' target="_blank" aria-label='Linkedin'><FontAwesomeIcon icon={faLinkedin}/></Link>
                        </Container> */}
                    </div>
                </div>
            </div>
        </section>
        </section>
        </div>
    </div>

    
  )
}

export default Footer