import React from 'react'
import './About.css'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';


function About() {
  return (
    <div>
            <Navbar/>
            {/* <div className='title-header'>About Muvr</div> */}
            {/* <div className='boxit'> */}
              <section>
              <div className='about'>About Muvr</div>
                {/* <div>Muvr was founded in 2021, born from an idea that revolutionizes the way moving solutions are offered and solving the last mile delivery problems.
                  <br/>
                  Muvr founders Renaldo Swartz and Tochukwu Oba created this application for people to interact and move things from everyday household and office furniture and items, farm produce, construction materials and so forth from point A to B. We seek to
                  enable affordable, reliable, safe ,timely  and seamless move.
                  <br/>
                  Muvr further strives towards bridging the economic gaps by using the platform to promote entrepreneurship and provide employment for truck drivers registered on the platform and at the same time alleviating stress of moving where a truck is not readily available.
                </div> */}
                <div className='description'>
                Muvr is an on-demand logistics app that connects truck/ bakkie drivers with users to enable them transport light to medium heavy items. We at Muvr are creating an ecosystem that makes it easier, safer and affordable to transport your parcels, goods and furniture, garden items, farm produce by using our easy-to-use mobile app.
                </div>
              </section>
            {/* </div> */}

            <Footer/>
            
    </div>
  )
}

export default About
