import React from 'react'
import {useState, useEffect} from 'react';
import {createUserWithEmailAndPassword} from "firebase/auth"
//import {useNavigate} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
//import { auth } from '../firebase_upgrade'
import { onAuthStateChanged } from "firebase/auth"
import auth from '../firebase_upgrade';
import {toast} from 'react-toastify'

const View = () => {

    const [data, setData] = useState({
        email: '',
        password: '',
    });

    //const navigate = useNavigate()
    const history = useHistory()

    const {firstname, lastname, user_name, email, password, password2} = data

    const OnChange = (e) => {
        const{name, value} = e.target;
        console.log(name,value)
        //setFormData({...formData,[name]: value});
        setData((prevState) =>({
          ...prevState,
          [e.target.name]: e.target.value}));
      }

    

    const onSubmit = e => {
        e.preventDefault()

        

        if(password !== password2){
            toast.error('Password do not match')
          }
          else {
            const email_used = email
            console.log('email',email_used)
            const password_used = password
            console.log('password_used', password_used)
            createUserWithEmailAndPassword(auth.auth,email_used,password_used)
            .then((cred)=> {
                console.log('user created:',cred.user)
                //navigate('/')
                history.push('/')
            })
            .catch((err)=>{
                console.log('error',err.message)
            })
          }
    }
    
    return (
        <div>
            <section className="heading">
            <h2>Register</h2>
            <p>Please create an anccount</p>
            </section>

            <section>
            <form onSubmit={onSubmit}>
            <div className='form-group'>
                <label htmlFor = "firstname">First Name</label>   
                <input 
                type="text" 
                className='form-control'
                id="firstname"
                value={firstname}
                name="firstname"
                placeholder="Enter Your First Name ..." 
                onChange={OnChange} 
                />
            </div>

            <div className='form-group'>
                <label htmlFor = "lastname">Last Name</label>   
                <input 
                type="text" 
                className='form-control'
                id="lastname"
                value={lastname}
                name="lastname"
                placeholder="Enter Your Last Name ..." 
                onChange={OnChange} 
                />
            </div>

            <div className='form-group'>
                <label htmlFor = "email">Email</label>   
                <input 
                type="text" 
                className='form-control'
                id="email"
                value={email}
                name="email"
                placeholder="Your Email ..." 
                onChange={OnChange} 
                />
            </div>

            <div className='form-group'>
                <label htmlFor = "password">Password</label>   
                <input 
                type="password" 
                className='form-control'
                id="password"
                value={password}
                name="password"
                placeholder="Enter Password" 
                onChange={OnChange} 
                />
            </div>

            <div className='form-group'>
                <label htmlFor = "password2">Confirm Password</label>   
                <input 
                type="password" 
                className='form-control'
                id="password2"
                value={password2}
                name="password2"
                placeholder="Confirm Password" 
                onChange={OnChange} 
                />
            </div>

            <div className='form-group'>
                <button type='submit' className='btn btn-block'>
                    Sign Up
                </button>
            </div>
            {/* 
            <input type="submit" value="Submit"/>  	 */}
            </form>
            </section>

        </div>
    )
}

export default View
