import React from 'react';
import './Cancelled.css'

const CancelledPage = () => {
    return (
      <div className="cancelled-page mt-5">
        <h1 className="text-center text-white">Your payment was cancelled</h1>
        <p className="text-center text-white">
          You cancelled your payment. Any reasons why? If not, you can try again.
        </p>
        <p className="text-center text-white">
          If you have any questions or concerns, please don't hesitate to contact us at info@muvr.co.za.
        </p>
      </div>
    );
  }
  
  export default CancelledPage;