import React from 'react'
import Header from '../components/Header'

const View = () => {
    return (
        <div>
            <Header/>
            <h2>Dashboard</h2>
        </div>
    )
}

export default View
