import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import db from '../firebase_upgrade'
import { ref, get,child } from 'firebase/database'
import "./Driver.css";
// import { ref, set, get, update,  remove, child, onValue } from 'firebase/database'
//import {Link,useNavigate} from "react-router-dom"
// import fireDb from "../firebase";
// import Driver from "../components/Driver"

function Users() {
    const [data, setData] = useState({});


    console.log(data);
    //console.log(setData);


    // useEffect(() => {
    //     fireDb.database().ref("contacts").on("value",(snapshot)=>{
    //         if(snapshot.val()!== null){
    //             setData({...snapshot.val()})
    //             console.log("snapshot" + snapshot.val())
    //             //navigate('/')
    //         }
    //         else{
    //             setData({});
    //         }
    //         // const drivers = snapshot.val();
    //         // const driverList = []
            
    //         // for (let id in drivers){
    //         //     driverList.push({id, ...drivers[id]});
    //         // }

    //         // setData(driverList);
    //         // console.log(driverList)

    //     });
    //     return () => {
    //         setData({})
    //     };
    // },[]);

    useEffect(() => {

        console.log(db.db)
        const dbRef = ref(db.db);
        get(child(dbRef, 'users/')).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            const drivers = snapshot.val();
            const driverList = [];
            for (let id in drivers){
                driverList.push({ id,  ...drivers[id] });
            }
            setData(driverList);
            console.log(driverList);
        } else {
            console.log("No data available");
        }
        }).catch((error) => {
        console.error(error);
        });

        // const driverRef = fireDb.database().ref("drivers");
        // driverRef.on("value", (snapshot) =>{
        //     const drivers = snapshot.val();
        //     const driverList = [];
        //     for (let id in drivers){
        //         driverList.push({ id,  ...drivers[id] });
        //     }
        //     setData(driverList);
        //     console.log(driverList);
        // });
    }, [])

  return (
      
    // <div>{driverList ? Drivers.map((driver)=> <Driver driver={driver} />)  :""}</div>
    
    <div style={{marginTop: "100px"}}>

        <table className='styled-table'>
            <thead>
                <tr>
                    <th style={{textAlign: "center"}}>No.</th>
                    <th style={{textAlign: "center"}}>First Name</th>
                    <th style={{textAlign: "center"}}>Last Name</th>
                    <th style={{textAlign: "center"}}>Email</th>
                    <th style={{textAlign: "center"}}>Phone</th>
                    <th style={{textAlign: "center"}}>Status</th>
                    <th style={{textAlign: "center"}}>Action</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map((id,index) => {
                    return(
                        <tr key={id}>
                            <th scope="row">{index + 1}</th>
                            <td>{data[id].fname}</td>
                            <td>{data[id].lname}</td>
                            <td>{data[id].email}</td>
                            <td>{data[id].phone}</td>
                            <td>{data[id].status}</td>
                            <td>
                                <Link to={`/update/${id}`}>
                                <button className='btn btn edit'>Edit</button>
                                </Link>    
                                <button className='btn btn delete'>Delete</button>
                                <Link to={`/view/${id}`}>
                                <button className='btn btn view'>View</button>
                                </Link>    
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>

        <div></div>
    </div>
  )
}

export default Users