import React from 'react'

function Profile() {
  return (
    <div>
        <h1>Profile Page</h1>
        <a href='/'>Logout</a>
    </div>
    
  )
}

export default Profile